import React, { useEffect } from 'react'

import MailchimpForm from './MailchimpForm'
import Heading from '../Heading/Heading'

import PlusIcon from '../../images/svgs/plus-icon.svg'

export default function MailchimpModal ( props ) {

	const { toggleModal, setToggleModal } = props

	useEffect(() => {

		// If the user presses the escape key, close the modal
		const handleUserKeyPress = e => {

			if ( e.keyCode === 27 && toggleModal ) {
				setToggleModal( !toggleModal )
			}
		}

		window.addEventListener( 'keydown', handleUserKeyPress )

		return () => {
			window.removeEventListener( 'keydown', handleUserKeyPress )
		}

	}, [ toggleModal, setToggleModal ])

	useEffect(() => {

		if ( toggleModal ) {
			const newScrollPosition = window.pageYOffset

			document.body.style.top = `-${newScrollPosition}px`
			document.body.classList.add('u-body-no-scroll')
		} else {
			const newScrollPosition = document.body.getBoundingClientRect().top

			document.body.classList.remove('u-body-no-scroll');
			document.body.style.removeProperty('top')

			window.scrollTo(0, Math.abs( newScrollPosition ))
		}
		
	}, [ toggleModal ] )

	return (
		<div className={`modal ${ toggleModal ? 'active' : '' }`}>
			<div className="modal__container">
				<Heading heading="Subscribe to Newsletter" subHeading={ true } />
				<PlusIcon className="modal__close" onClick={ () => setToggleModal( !toggleModal ) } />
				<div className="modal__form-container">
					<MailchimpForm />
				</div>
			</div>
		</div>
	)
}