import React, { useState, useRef } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import axios from 'axios'

import Anchor from '../../Anchor/Anchor'
import Heading from '../../Heading/Heading'

import Loader from '../../../images/svgs/loader.svg'

const EMAIL_KEY = process.env.EMAIL_KEY || 'kieron123'

export default function ContactForm ( props ) {

	const data = props.input

	const form = useRef();
	const [ firstName, setFirstName ] = useState('')
	const [ lastName, setLastName ] = useState('')
	const [ email, setEmail ] = useState('')
	const [ messageDetails, setMessageDetails ] = useState('')
	const [ checked, toggleChecked ] = useState( false )
	const [ formSuccess, setFormSuccess ] = useState( false )
	const [ error, setError ] = useState('')
	const [ loading, setLoading ] = useState()

	const handleSubmit = async ( event ) => {
		event.preventDefault()
		form.current.reportValidity();

		setLoading( true )

		const formData = {
			name: `${ firstName } ${ lastName }`,
			email,
			messageDetails,
			EMAIL_KEY
		}

		try {
			const result = await axios.post('/api/email', formData);

			if ( result.status === 200 ) {
				setFormSuccess( true )
				setLoading( false )
			}
		} catch ( error ) {
			console.log( error )
			setError('Error')
			setLoading( false )
		}

		if ( checked ) {
			addToMailchimp( email, { FNAME: firstName, LNAME: lastName } )
				.then( data => {
					// Don't need to do anything
				} )
				.catch( () => {
					setError( true )
				} )
		}
	}

	return (
		<section className="contact-us">
			<Anchor anchorId="contact" />
			<Heading heading={ data.contactTitle } subHeading={ true } />
			<div className="contact-us__wrapper wrapper">
				{ !formSuccess ? (
					<form className="form" onSubmit={ handleSubmit } ref={ form }>
						<div className={`form__input-container ${ error ? 'error' : '' }`}>
							<label className="form__label" htmlFor="firstName">Your first name</label>
							<input
								onChange={ e => { setFirstName( e.target.value ) }}
								value={ firstName }
								className="form__input form__input--blue" id="firstName" type="text" placeholder="Your first name" required />
						</div>

						<div className={`form__input-container ${ error ? 'error' : '' }`}>
							<label className="form__label" htmlFor="lastName">Your last name</label>
							<input
								onChange={ e => { setLastName( e.target.value ) }}
								value={ lastName }
								className="form__input form__input--yellow" id="lastName" type="text" placeholder="Your last name" required />
						</div>

						<div className={`form__input-container ${ error ? 'error' : '' }`}>
							<label className="form__label" htmlFor="email">Your email</label>
							<input
								onChange={ e => { setEmail(e.target.value) }}
								value={ email }
								className="form__input form__input--pink" id="email" type="email" placeholder="Your email" required />
						</div>

						<div className={`form__input-container ${ error ? 'error' : '' }`}>
							<label className="form__label" htmlFor="message">Message</label>
							<textarea
								onChange={ e => { setMessageDetails(e.target.value) }}
								value={ messageDetails}
								className="form__input form__input--green" id="message" type="text" placeholder="Message" rows="6" required />
						</div>

						<div className="form__input-container">
								<label className="form__checkbox-label" htmlFor="subscribeToNewsletter">
								<input
									className="form__checkbox-input"
									type="checkbox"
									id="subscribeToNewsletter"
									defaultChecked={ checked }
									onChange={ () => { toggleChecked( !checked ) } }/>
									<div className="form__checkbox-checkmark"></div>
								<span className="form__checkbox-text">Subscribe to Newsletter</span>
							</label>
						</div>

						<input type="hidden" id="" name="" value=""></input>

						{ error.length >= 1 ? (
							<div className="form__error-container">
								<span className="form__error-message">Sorry, we have been unable to send your message.</span>
								<span className="form__error-message">Please try again later.</span>
							</div>
						) : (
							<button className="form__submit" type="submit">Send</button>
						) }

						{ loading &&
							<div className="form__loading-container">
								<div className="form__loading-background"></div>
								<Loader className="loader" />
								<h3 className="form__loading-text">Sending</h3>
							</div>
						}
					</form>
				) : (
					<p className="contact-us__success">
						Thank you for getting in touch. We will respond to your message as soon as possible!
					</p>
				) }
			</div>
		</section>
	)
}